<template>
  <div class="home">
    <div class="login-container">
      <div class="language">
        <img src="@/assets/boao.png" fit="fill" width="120px" />
        <div>
          <van-button
            size="small"
            :class="{ 'checked': languages[0].code === language }"
            @click="onSelect(languages[0])"
          >
            {{ languages[0].name }}
          </van-button>
          <van-button
            size="small"
            :class="{ 'checked': languages[1].code === language }"
            @click="onSelect(languages[1])"
          >
            {{ languages[1].name }}
          </van-button>
        </div>
      </div>

      <div class="login">
        <div>
          <div class="company-name">博鳌亚洲论坛酒店预订系统</div>
          <div class="company-en-name">Boao Forum For Asia Hotel Reservation Service</div>
        </div>
        <div v-if="language === 'zh'" class="login-field">
          <div>{{ $t('lastName') }}<span style="color: red;">（请输入拼音）</span></div>
          <input
            type="text"
            v-model="last"
            maxlength="50"
            @blur="last = last.trim()"
          />
        </div>
        <div v-if="language === 'zh'" class="login-field">
          <div>{{ $t('firstName') }}<span style="color: red;">（请输入拼音）</span></div>
          <input
            type="text"
            v-model="first"
            maxlength="50"
            @blur="first = first.trim()"
          />
        </div>

        <div v-if="language === 'en'" class="login-field">
          <div>{{ $t('firstName') }}</div>
          <input
            type="text"
            v-model="first"
            maxlength="50"
            @blur="first = first.trim()"
          />
        </div>
        <div v-if="language === 'en'" class="login-field">
          <div>{{ $t('lastName') }}</div>
          <input
            type="text"
            v-model="last"
            maxlength="50"
            @blur="last = last.trim()"
          />
        </div>

        <div class="login-field">
          <div>{{ $t('invitationCode') }}</div>
          <input
            type="text"
            v-model="invitationCode"
            maxlength="50"
            @blur="invitationCode = invitationCode.trim()"
          >
        </div>

        <van-button
          class="login-button"
          block
          @click="login"
          :loading="loading"
        >
          {{ $t('login') }}
        </van-button>
      </div>
    </div>

    <div class="footer">
      <div style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
        <div>
          <img src="@/assets/dongfangmeijing.png" width="100" />
        </div>
        <div style="font-size: 14px;">
          <div>{{ $t('for more information') }}</div>
          <div>
            <span>{{ $t('footer customer tel') }}: +86-400-098-2996</span>
          </div>
          <div>
            <span>Email: hotel@bjome.com.cn</span>
          </div>
        </div>
      </div>
    </div>

    <van-popup v-model="showService" closeable :style="{ 'min-height': '50%', width: '86%' }">
      <div style="padding: 2.5em 1.4em;">
        <van-row type="flex">
          <van-col span="5" style="background: #eee; display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <div>
              <van-icon name="warning-o" size="40" color="orange" />
            </div>
            <div>
              {{ $t('sorry') }}
            </div>
          </van-col>
          <van-col span="19" style="padding: 0 0 0 10px;">
            <div class="word">{{ $t('login notify') }}</div>
            <div class="word">
              <span>{{ $t('customer tel') }}: </span>
              <span class="important-text">+86-400-098-2996</span>
            </div>
            <div class="word">
              <span>{{ $t('customer email') }}: </span>
              <span class="important-text">hotel@bjome.com.cn</span>
            </div>
          </van-col>
        </van-row>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Notify } from 'vant'
import { mapState } from 'vuex'
import { vanLocales } from '@/lang'

export default {
  name: 'Home',
  components: {

  },
  data () {
    return {
      showLanguage: false,
      languages: [
        { code: 'zh', name: '中文' },
        { code: 'en', name: 'English' }
      ],
      last: '',
      first: '',
      invitationCode: '',
      showService: false,
      loading: false
    }
  },
  computed: {
    ...mapState({
      language: state => state.language
    })
  },
  methods: {
    onSelect (value) {
      this.$i18n.locale = value.code
      vanLocales(value.code)
      this.$store.dispatch('setLanguage', value.code)
    },
    check () {
      if (!this.last || !this.last.trim()) {
        return false
      }

      if (!this.first || !this.first.trim()) {
        return false
      }

      if (!this.invitationCode || !this.invitationCode.trim()) {
        return false
      }

      return true
    },
    async login () {
      // check user input
      if (this.check()) {
        // backend login
        try {
          this.loading = true
          const res = await this.$axios.post('/invitation/login', {
            last: this.last.trim(),
            first: this.first.trim(),
            invitationCode: this.invitationCode.trim()
          })

          this.$store.dispatch('setToken', res.token)
          this.$router.push('search')
        } catch (e) {
          Notify(e.message)
          this.showService = true
          console.error(e)
        } finally {
          this.loading = false
        }
      } else {
        this.showService = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
@blue:#0d2d84;
@gold:#ffb200;

.home {
  font-family: "Microsoft YaHei Bold", "Microsoft YaHei", Arial, Avenir, Helvetica, sans-serif;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url('../assets/top.png');
  background-repeat: no-repeat;
  background-size: 100%;
}

.login-container {
  background-color: white;
  margin-top: clamp(100px, 30%, 300px);
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  .company-name {
    margin-top: 30px;
  }

  .company-name, .company-en-name {
    color: @blue;
    font-weight: bold;
  }

  .company-en-name {
    font-weight: unset;
  }

  .login {
    width: 86%;
    max-width: 600px;
    padding-bottom: 20px;
  }
}

.language {
  width: 86%;
  max-width: 600px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 0 0;

  .van-button {
    border: none !important;
    background-color: @gold;
    color: @blue;
    width: 70px;
  }

  .van-button.checked {
    background-color: @blue;
    color: @gold;
  }

  .van-button {
    border-radius: 6px 0 0 6px !important;
  }

  .van-button:last-child {
    border-radius: 0 6px 6px 0 !important;
  }
}

.login-field > input {
  box-sizing: border-box;
  width: 100%;
  height: 44px;
  padding: 0 16px;
  border: none;
  border-radius: 6px;
  background-color: #f5f6f7;;
}

.login-field {
  margin-top: 20px;
  div:first-child {
    color: #636363;
    padding-bottom: 8px;
    box-sizing: border-box;
    height: 30px;
  }
}

.login-field:not(:first-child) {
  margin-top: 16px;
}

.login-button {
  margin-top: 30px;
  border-radius: 10px;
  background-color: @blue;
  color: @gold;
  font-size: 1.5em;
}

.footer {
  background-color: rgba(255, 255, 255, 0.96);
  color: #9e9e9e;
  font-size: 14px;
  padding-bottom: 30px;
  span {
    padding-right: 15px;
  }
}

.link {
  font-family: "Microsoft YaHei Bold", "Microsoft YaHei", Arial, Avenir, Helvetica, sans-serif !important;
  color: #9e9e9e;
}
</style>
